<template>
  <div class="wrapper">
    <b-row v-if="loading" class="d-flex justify-content-center">
      <b-spinner label="Loading..." variant="primary" />
    </b-row>
    <b-card title="Details">
      <b-row v-if="!loading">
        <!-- User Info: Left col -->
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar src="img/13-small.png" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">{{ userData.firstName }} {{ userData.lastName }}</h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                {{ userData.userName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">First Name</span>
              </th>
              <td class="pb-50">
                {{ userData.firstName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Last Name</span>
              </th>
              <td class="pb-50">
                {{ userData.lastName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge v-if="userData.isActive" variant="success"> Active </b-badge>
                <b-badge v-else variant="danger"> Inactive </b-badge>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ userData.phoneNumber }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Roles">
      <b-row v-if="!loading">
        <div class="table-responsive">
          <table class="table table-flush-spacing">
            <tbody>
              <tr>
                <td class="text-nowrap fw-bolder">
                  Administrator Access
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Allows a full access to the system"
                  >
                    <i data-feather="info"></i>
                  </span>
                </td>
                <td>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="selectAll"
                      v-model="selectAllData"
                      @change="selectAllFunction(selectAllData)"
                    />
                    <label class="form-check-label" for="selectAll"> Select All </label>
                  </div>
                </td>
              </tr>
              <tr v-for="item in roles" :key="item.roleId">
                <td class="text-nowrap fw-bolder">{{ item.roleName }}</td>
                <td>
                  <div class="d-flex">
                    <div class="form-check me-3 me-lg-5">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="item.enabled"
                        :id="item.roleId"
                      />
                      <label class="form-check-label" :for="item.enabled"> Grant </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <b-button variant="primary" @click="addRole"
            ><b-spinner v-if="loading" small> </b-spinner> Update User Roles
          </b-button>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BSpinner, BBadge } from "bootstrap-vue";
import api from "@/api/api-v1-config";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BBadge,
  },

  data() {
    return {
      token: localStorage.getItem("accessToken"),
      userData: {},
      loading: true,
      selectedRole: [],
      id: this.$route.params.id,
      selectAllData: false,
    };
  },
  computed: {
    ...mapGetters("userModule", {
      roles: "role",
      loading: "loading",
    }),
  },
  methods: {
    selectAllFunction(item) {
      if (item) {
        this.roles.forEach((role) => {
          role.enabled = true;
        });
      }
      if (!item) {
        this.roles.forEach((role) => {
          role.enabled = false;
        });
      }
    },
    ...mapActions("userModule", ["getUserRoleAction", "addUserRoleAction"]),
    successRole() {
      this.$swal({
        title: "Role Updated!",
        text: "You have successfully updated a Role!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    errorRole(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data.errors);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(errors) {
      let msg = null;
      if (errors) {
        msg = "<div>";
        msg = "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
        return msg;
      }
    },
    addRole() {
      let payload = {
        userRoles: this.selectedRole.map((role) => {
          return {
            roleId: role.roleId,
            roleName: role.roleName,
            description: role.description,
            enabled: role.enabled,
          };
        }),
      };
      console.log(payload);
      api.defaults.headers.Authorization = "Bearer " + this.token;
      api
        .post("users/" + this.id + "/roles", payload)
        .then((response) => {
          this.getUserRoleAction(this.id);
          this.successRole();
        })
        .catch((error) => {
          this.errorRole(error.response.data);
        });
    },
    async getUserData() {
      api.defaults.headers.Authorization = "Bearer " + this.token;
      await api
        .get("users/" + this.id)
        .then((response) => {
          this.userData = response.data.result;
          this.loading = false;

          console.log(this.userData);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    roles: {
      handler(newRoles) {
        // Check if all roles are enabled
        const allRolesEnabled = newRoles.every((role) => role.enabled);
        // Update the selectAllData property based on the new status of allRolesEnabled
        this.selectAllData = allRolesEnabled;
      },
      deep: true, // Allows watcher to watch for changes within nested objects or arrays
    },
  },

  async mounted() {
    await this.getUserRoleAction(this.id)
      .then((response) => {
        this.roles.forEach((element) => {
          this.selectedRole.push(element);
        });
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });
  },

  created() {
    this.getUserData();
  },
};
</script>
