<template>
  <b-tabs>
    <b-tab title="User Details" active>
      <UserCard />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import UserCard from "./components/UserCard.vue";

export default {
  components: {
    BTabs,
    BTab,
    UserCard,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>
